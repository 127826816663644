import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Float } from '@components/LPCard/Common'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPActionPlan, IPUnit, IPUrl } from './interface'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import AITutorLink from '../Common/AITutorLink'

const Completed: React.FC<IPUrl & IPActionPlan & IPUnit> = ({ url, redo, unit }) => {
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  return (
    <>
      <Spacer size="sm" />
      <Float>
        <Button
          disabled={!url}
          color="success"
          narrow
          onClick={() => {
            if (state.allowed) {
              return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', url)
            }

            url && window.open(url, '_self')
          }}
        >
          <FormattedMessage id="Action plan" />
        </Button>
      </Float>
      <Spacer size="xl" />
      {/*<Float>*/}
      {/*  <Button disabled={!redo} color="default" narrow onClick={() => redo && window.open(redo, '_self')}>*/}
      {/*    <FormattedMessage id="Redo" />*/}
      {/*  </Button>*/}
      {/*</Float>*/}

      <AITutorLink unit={unit} status="unlocked" txtColor="white" btnColor="brandSecondary" />
    </>
  )
}

export default Completed
