import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.xxxs}`};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.33);

  svg {
    margin-left: ${({ theme }) => theme.space.xxxs};
    margin-right: ${({ theme }) => theme.space.xxxs};
    font-size: 20px;
  }

  .ratings,
  .placeholder {
    display: flex;
  }

  .placeholder {
    svg {
      color: ${({ theme }) => theme.palette.gray50};
    }
  }
`
