import * as React from 'react'
import { Tick } from '@berlitz/icons'
import isNumber from 'lodash/isNumber'

import { IPRating } from './interface'
import { Wrapper, Icon } from './style'

export const isCompleted = (rating: number) => rating >= 100 && isNumber(rating)

const Rating: React.FC<IPRating> = ({ rating }) => {
  const status = isCompleted(rating) ? 'success' : 'in-progress'
  const isSuccess = status === 'success'
  return (
    <Wrapper status={status}>
      {isSuccess && <Icon status={status}>{isSuccess && <Tick />}</Icon>}
      {rating}%
    </Wrapper>
  )
}

export default Rating
