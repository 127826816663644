import Spacer from '@berlitz/spacer'
import Lock2 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock2'
import {
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconCircle,
  LinkButton,
  Subtitle,
  Tag,
  Title,
} from '@components/LPCard/Common'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Button from '@components/Button'
import { TopTag } from '../Lesson/style'
import { AIWrapper, AISubtitle } from './style'
import { IPLocked } from './interface'
import WhatModal from './WhatModal'
import AITutorLink from '../Common/AITutorLink'

export const Locked: React.FC<IPLocked> = ({ Unit }) => {
  const [modalOpen, setModalOpen] = React.useState(false)

  return (
    <Card type="speakPrac">
      <WhatModal open={modalOpen} onClose={() => setModalOpen(false)} />

      <CardContent isElective={Unit?.IsFromElective}>
        <CardHeader>
          {Unit?.IsFromElective && (
            <TopTag color="blue100">
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </TopTag>
          )}
          <Icon color="info100">
            <IconCircle color="white" />
            <Lock2 />
          </Icon>
        </CardHeader>

        <Spacer size="md" />

        <Tag>
          <FormattedMessage id="Locked" />
        </Tag>

        <Spacer size="xs" />

        <Title>
          {Unit?.LCSTitle} <FormattedMessage id="locked" />
        </Title>

        <Spacer size="sm" />

        <Subtitle>
          <FormattedMessage id="Complete previous lessons to unlock." />
          <Spacer size="xs" />

          <LinkButton onClick={() => setModalOpen(true)}>
            <FormattedMessage id="What is a live coaching session?" />
          </LinkButton>
        </Subtitle>

        <Spacer size="xl" />

        <AITutorLink unit={Unit} status="locked" txtColor="white" btnColor="brandSecondary" />
      </CardContent>
    </Card>
  )
}

export default Locked
