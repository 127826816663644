import styled, { css } from 'styled-components'
import { IPColor } from '../interface'

export const AIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export const Subtitle = styled.div<IPColor>`
  color: ${({ theme, color = 'white' }) => theme.palette[color]};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const LockButton = styled.div<IPColor>`
  padding: 4px 24px;
  border-radius: ${({ theme }) => theme.radius.md};
  background-color: ${({ theme, color = 'brandPrimary' }) => theme.colors[color]};
`

export const Icon = styled.div<IPColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;

  svg {
    z-index: 1;

    ${({ theme, color = 'white' }) => css`
      color: ${theme.palette[color]};
    `}
  }
`
