import styled, { css } from 'styled-components'
import { Subtitle, IPColor } from '@components/LPCard/Common'

export const SubContainer = styled.div<IPColor & { shadowed?: boolean }>`
  padding: ${({ theme }) => theme.space.xs};
  position: relative;
  text-align: center;
  width: 100%;

  ${({ theme, color = 'white' }) => css`
    background-color: ${theme.palette[color]};
  `}

  ${({ shadowed }) =>
    shadowed &&
    css`
      border-radius: ${({ theme }) => theme.radius.md};
      box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
    `}

  span[data-key='icon-IconInformationCircle'] {
    font-size: ${({ theme }) => theme.fontSize.xs};
    position: absolute;
    z-index: 1;
    left: -6px;
    top: -6px;
  }

  .narrow {
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
  }
`

export const Description = styled(Subtitle)`
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  letter-spacing: 0.42px;
`

export const SelectContaier = styled.div`
  width: 100%;
  > div > label {
    font-size: ${({ theme }) => theme.fontSize.xxxs};
  }

  .ant-select {
    width: 100%;
  }
`

export const SubTitle = styled.div<IPColor>`
  display: flex;
  line-height: 22px;
  letter-spacing: 0.56;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};

  svg {
    font-size: 20px;
    ${({ theme, color = 'white' }) => css`
      color: ${theme.palette[color]};
    `}
  }

  > span:last-child {
    top: ${({ theme }) => theme.space.xxs};
    left: ${({ theme }) => theme.space.xxs};
    position: relative;
  }

  ${({ theme, color = 'white' }) => css`
    color: ${theme.palette[color]};
  `}
`

export const SubDescription = styled.div<IPColor>`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.xxs};

  svg {
    font-size: 20px;
    ${({ theme, color = 'white' }) => css`
      color: ${theme.palette[color]};
    `}
  }

  > span:last-child {
    top: ${({ theme }) => theme.space.xxs};
    left: ${({ theme }) => theme.space.xxs};
    position: relative;
    text-align: left;
  }

  > div {
    justify-content: center;
    width: 100%;
  }

  ${({ theme, color = 'white' }) => css`
    color: ${theme.palette[color]};
  `}
`

export const Link = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  color: ${({ theme }) => theme.colors.brandPrimary};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  text-decoration: underline;
  cursor: pointer;
  transition: 0.4s;
`

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > span.text-label {
    padding-left: ${({ theme }) => theme.space.xs};
  }
`

export const AIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 86px;
`

export const AISubtitle = styled.div<IPColor>`
  color: ${({ theme, color = 'white' }) => theme.palette[color]};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
