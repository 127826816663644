import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { CardModal } from '@components/LPCard/Common'

import { IPModal } from './interface'

const WhyModal: React.FC<IPModal> = ({ open, onClose }) => {
  return (
    <CardModal open={open} onClose={onClose}>
      <CardModal.Title>
        <FormattedMessage id="Why do I have to book?" />
      </CardModal.Title>
      <CardModal.Desc>
        <FormattedMessage id="Your 30-minute live coaching session takes place with a Berlitz instructor via a video call. Select a time and date and we'll match with an instructor." />
      </CardModal.Desc>
      <CardModal.CloseBtn onClick={() => onClose()}>
        <FormattedMessage id="Tap to close" />
      </CardModal.CloseBtn>
    </CardModal>
  )
}

export default WhyModal
