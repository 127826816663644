import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Float, LinkButton, Subtitle } from '@components/LPCard/Common'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPUnit, IPUrl } from './interface'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import AITutorLink from '../Common/AITutorLink'
interface IPUnlocked {
  toggleModal: Function
}

const Unlocked: React.FC<IPUnlocked & IPUrl & IPUnit> = ({ url, toggleModal, unit }) => {
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  return (
    <>
      <Spacer size="xs" />

      <Subtitle>
        <FormattedMessage id="Review everything you've learnt recently, and rate your knowledge." />
      </Subtitle>

      <Spacer size="xs" />

      <LinkButton onClick={() => toggleModal()}>
        <FormattedMessage id="What is a checkpoint?" />
      </LinkButton>

      <Spacer size="md" />

      <Float>
        <Button
          disabled={!url}
          color="success"
          narrow
          onClick={() => {
            if (state.allowed) {
              return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', url)
            }

            window.open(url, '_self')
          }}
        >
          <FormattedMessage id="Start" />
        </Button>
      </Float>
      <Spacer size="xl" />
      <AITutorLink unit={unit} status="locked" txtColor="white" btnColor="brandSecondary" />
    </>
  )
}
export default Unlocked
