import React from 'react'
import InformationCircle from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/14-Alerts/InformationCircle'
import { FormattedMessage } from 'react-intl'
import { Description, SubContainer } from '../SPPanel/style'

const InfoCard: React.FC = () => {
  return (
    <SubContainer color="info30">
      <InformationCircle />
      <Description color="text00">
        <FormattedMessage id="Practice what you've learnt with an instructor, online via video. (approx. 30 minutes)" />
      </Description>
    </SubContainer>
  )
}

export default InfoCard
