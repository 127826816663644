import styled, { css } from 'styled-components'
import { IPWithBackdrop } from './interface'

export const CloseBtn = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.palette.blue100};
  text-decoration: underline;
  margin-top: ${({ theme }) => theme.space.md};
  margin-bottom: ${({ theme }) => theme.space.xs};
  cursor: pointer;
`

export const Desc = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.42px;
  text-align: center;
`

export const Title = styled.div`
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.45;
  letter-spacing: 0.56px;
  text-align: center;
  margin-bottom: ${({ theme }) => theme.space.xxs};
`

export const Content = styled.div<IPWithBackdrop>`
  padding: ${({ theme }) => `${theme.space.sm} 14px`};
  margin-left: 17px;
  margin-right: 17px;
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.4);
  background-color: ${({ theme }) => theme.palette.white};
  z-index: 1;

  ${({ withBackdrop }) =>
    withBackdrop &&
    css`
      z-index: 2;
    `}
`

export const ModalBG = styled.div<IPWithBackdrop>`
  position: absolute;
  width: 100%;
  height: 100%;

  ${({ withBackdrop }) =>
    withBackdrop &&
    css`
      background-color: rgb(29, 29, 29, 0.82);
      z-index: 2;
      border-radius: 12px;
    `}
`

export const Wrapper = styled.div`
  position: absolute;
  width: 230px;
  height: 386px;
  display: flex;
  justify-content: center;
  align-items: center;
`
