import Button from '@berlitz/button'
import Spacer from '@berlitz/spacer'
import LockUnlock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/LockUnlock1'
import LearningPath from '@classes/LearningPath'
import LearningPathUnit from '@classes/LearningPathUnit'
import { Card, CardContent, Icon, IconCircle, Title } from '@components/LPCard/Common'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SubContainer, SubDescription, SubTitle } from './style'

export const UnlimitedLCS: React.FC<{
  Unit?: LearningPathUnit
  allBooked?: boolean
  Path?: LearningPath
}> = ({ Path }) => (
  <Card type="speakingPanel" completed withConfetti active>
    <CardContent>
      <Icon color="white">
        <IconCircle color="white" opacity="0.27" />
        <LockUnlock1 />
      </Icon>

      <Spacer size="md" />

      <Title>
        <FormattedMessage id="Live Coaching Session" />
      </Title>

      <Spacer size="sm" />

      <SubContainer shadowed>
        <Spacer size="sm" />

        <SubTitle color="blue100">
          <FormattedMessage id="Practice makes perfect" />
        </SubTitle>

        <Spacer size="sm" />

        <SubDescription color="charcoal">
          <FormattedMessage id="You now have access to unlimited coaching sessions." />
        </SubDescription>

        <Spacer size="sm" />
        <Button
          onClick={() => {
            Router.push(`/scheduling/single-lesson?programid=${Path?.Program?.ProgramId}&unitId=0000&LPId=${Path?.Id}`)
          }}
        >
          <FormattedMessage id="Book a session" />
        </Button>
        <Spacer size="md" />
      </SubContainer>
    </CardContent>
  </Card>
)

export default UnlimitedLCS
