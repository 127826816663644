import RatingStar from '@berlitz/streamline-icons/lib/bold/22-Social-Medias-Rewards-Rating/06-Rating/RatingStar'
import * as React from 'react'
import { Wrapper } from './style'

const Stars: React.FC<{ stars?: number }> = ({ stars = 0 }) => (
  <Wrapper>
    <div className="ratings">
      {Array.from(Array(stars).keys()).map((count) => (
        <RatingStar key={count} />
      ))}
    </div>
    <div className="placeholder">
      {Array.from(Array(5 - stars).keys()).map((count) => (
        <RatingStar key={count} />
      ))}
    </div>
  </Wrapper>
)

export default Stars
