import BlzButton from '@berlitz/button'
import Spacer from '@berlitz/spacer'
import Calendar1 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import LearningPathUnit from '@classes/LearningPathUnit'
import JoinLessonButton from '@components/JoinLessonButton'
import { Card, CardContent, Icon, IconCircle, Title, Tag, CardHeader, CardHeaderCol } from '@components/LPCard/Common'
import WithUserTime, { WithUserTimeProps } from '@hocs/WithUserTime'
import { DATE_FORMAT } from '@utils/constants'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, SubContainer, SubDescription } from './style'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import { TopTag } from '../Lesson/style'
import useTimeFormat from '@hooks/useTimeFormat'

export const JoinOption: React.FC<
  {
    Unit: LearningPathUnit
  } & WithUserTimeProps
> = ({ getDateWithUserTimezone, Unit }) => {
  const { convertToUserTimeFormats } = useTimeFormat()
  const activeUnit = Unit
  const lesson = activeUnit?.Session?.lesson
  const startTime = activeUnit?.Session?.StartTime || lesson?.StartTime
  const sessionStartTime = getDateWithUserTimezone(startTime)
  const isPastLesson = sessionStartTime.isValid() && sessionStartTime.isBefore(getDateWithUserTimezone())
  const isLCSZero = Unit.IsLCSZero
  const cardType = isLCSZero ? 'lcs0' : 'speakPrac'

  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })
  const CHeader = Unit?.IsFromElective ? CardHeader : CardHeaderCol
  return (
    <Card type={cardType} withHalo={isLCSZero}>
      <CardContent isElective={Unit?.IsFromElective}>
        <CHeader>
          {Unit?.IsFromElective && (
            <TopTag color="blue100">
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </TopTag>
          )}
          {isLCSZero ? (
            <>
              <Icon color="charcoal">
                <IconCircle color="green100" />
                <Calendar1 />
              </Icon>
              <Spacer size="md" />
              <Tag>
                <FormattedMessage id="Booked" defaultMessage="Booked" />
              </Tag>
              <Spacer size="xs" />
            </>
          ) : (
            <Icon color="white">
              <IconCircle color="white" opacity="0.27" />
              <Calendar1 />
            </Icon>
          )}
        </CHeader>
        <Spacer size="xs" />
        <Title color="white">
          {lesson?.UnitId ? activeUnit?.LCSTitle || '' : 'Live Coaching Session'}{' '}
          {!startTime && isPastLesson && (
            <span className="lowercase">
              <FormattedMessage id="Completed" />
            </span>
          )}
        </Title>
        <Spacer size="sm" />
        <SubContainer shadowed>
          <SubDescription color="charcoal">
            <Calendar1 />
            <span>{convertToUserTimeFormats(sessionStartTime.format(DATE_FORMAT.COMPLETE), DATE_FORMAT.COMPLETE)}</span>
          </SubDescription>
          <Spacer size="sm" />
          <JoinLessonButton title="Join" className="join-lesson" lesson={lesson} fullWidth />
          <Spacer size="xs" />
          {!isLCSZero && (
            <>
              <BlzButton
                className="narrow"
                outline
                fullWidth
                disabled={!activeUnit?.EntryPointUrl}
                onClick={() => {
                  if (state.allowed) {
                    return features.cr8.redirectToCR8(Unit?.LearningPathId ?? '', activeUnit?.EntryPointUrl)
                  }

                  window.open(activeUnit?.EntryPointUrl, '_self')
                }}
              >
                <FormattedMessage id="Performance Card" />
              </BlzButton>
              {activeUnit?.ActionPlanUrl && (
                <>
                  <Spacer size="xxs" />
                  <BlzButton
                    className="narrow"
                    outline
                    fullWidth
                    onClick={() => {
                      if (state.allowed) {
                        return features.cr8.redirectToCR8(Unit?.LearningPathId ?? '', activeUnit?.ActionPlanUrl)
                      }

                      window.open(activeUnit?.ActionPlanUrl, '_self')
                    }}
                  >
                    <FormattedMessage id="Action Plan" />
                  </BlzButton>
                </>
              )}
            </>
          )}
          <Spacer size="xs" />
          <Link onClick={() => Router.push(`/scheduling/my-calendar/`)}>
            <FormattedMessage id="View Calendar" />
          </Link>
          <Spacer size="xs" />
        </SubContainer>
      </CardContent>
    </Card>
  )
}
export default WithUserTime(JoinOption)
