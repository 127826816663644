import styled from 'styled-components'
import { IPColor } from '../Common'

export const RatingWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 190px;
`

export const DateTimeBox = styled.div`
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;

  svg {
    font-size: 20px;
    margin-right: ${({ theme }) => theme.space.xs};
    color: ${({ theme }) => theme.palette.blueGray70};
  }
`

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

export const AIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 86px;
`

export const AISubtitle = styled.div<IPColor>`
  color: ${({ theme, color = 'white' }) => theme.palette[color]};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
