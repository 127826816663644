import Button from '@berlitz/button'
import Spacer from '@berlitz/spacer'
import Tick from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/33-Form-Validation/Check'
import AwardTrophyStart1 from '@berlitz/streamline-icons/lib/bold/22-Social-Medias-Rewards-Rating/08-Rewards-Awards/AwardTrophyStar1'
import LearningPathUnit from '@classes/LearningPathUnit'
import { Card, CardContent, Icon, IconCircle, Title } from '@components/LPCard/Common'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SubContainer, SubDescription, SubTitle } from './style'

export const Completed: React.FC<{ Unit?: LearningPathUnit; allBooked?: boolean }> = ({ Unit }) => (
  <Card type="speakingPanel" completed withConfetti active>
    <CardContent>
      <Icon color="white">
        <IconCircle color="white" opacity="0.27" />
        {Unit?.StudentProgram?.areLessonsAllBooked() ? <AwardTrophyStart1 /> : <Tick />}
      </Icon>

      <Spacer size="md" />

      <Title>
        <FormattedMessage id={Unit?.StudentProgram?.areLessonsAllBooked() ? 'Congratulations!' : 'Well done!'} />
      </Title>

      <Spacer size="sm" />

      <SubContainer shadowed>
        <Spacer size="sm" />

        <SubTitle color="blue100">
          {Unit?.StudentProgram?.areLessonsAllBooked() ? (
            <FormattedMessage id="You've completed all Live Coaching Sessions" />
          ) : (
            <FormattedMessage
              id="completed-xx-lessons"
              defaultMessage="You've completed {count} Live Coaching Sessions"
              values={{ count: Unit?.LCSCount }}
            />
          )}
        </SubTitle>

        <Spacer size="sm" />

        <SubDescription color="charcoal">
          <div>
            {Unit?.StudentProgram?.areLessonsAllBooked() ? (
              <FormattedMessage id="Interested in another course?" />
            ) : (
              <FormattedMessage id="Practice makes perfect" />
            )}
          </div>
        </SubDescription>

        <Spacer size="sm" />

        {Unit?.StudentProgram?.areLessonsAllBooked() ? (
          <Button
            onClick={() => {
              Router.push(`/support/contact-us/?id=my-lessons&cat=Add%20on%20products`)
            }}
          >
            <FormattedMessage id="Contact us" />
          </Button>
        ) : (
          <Button
            onClick={() => {
              Router.push(
                `/scheduling/single-lesson?programid=${Unit?.StudentProgram?.ProgramId}&unitId=0000&LPId=${Unit?.LPId}`
              )
            }}
          >
            <FormattedMessage id="Book another session" />
          </Button>
        )}
        <Spacer size="md" />
      </SubContainer>
    </CardContent>
  </Card>
)

export default Completed
