import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Subtitle, AIWrapper, LockButton, Icon } from './style'
import { StoreProps } from '@interfaces/StoreState'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import PortalStudentProfile from '@classes/StudentProfile'
import PortalLearningPathUnit from '@classes/LearningPathUnit'
import Lock2 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock2'

interface AITutorLinkProps {
  status: 'locked' | 'unlocked'
  txtColor?: 'black' | 'white'
  btnColor?: 'brandSecondary' | 'brandPrimary'
  unit?: PortalLearningPathUnit
}

type LockButtonProps = Pick<AITutorLinkProps, 'status' | 'unit' | 'btnColor'>

const LockButtonWrapper: React.FC<LockButtonProps> = ({ status, unit, btnColor }) => {
  const student = useSelector(
    ({ userProfile }: StoreProps) => new PortalStudentProfile(userProfile?.info?.StudentProfile?.StudentProfileInfo)
  )
  const learningPath = useSelector(({ activeState }: StoreProps) => activeState.learningPath)

  const openAI = () => {
    const opt = {
      interfaceLang: student?.student?.PortalLanguage,
      userId: student?.SFId,
      level: learningPath?.Level?.[0] || 1,
      targetLang: learningPath?.learningPath?.details?.language ?? 'en',
    }

    unit?.AITutorLink && window.open(unit?.generateAITutorLink(opt), '_self')
  }

  if (unit?.HasContractEnded) {
    return (
      <LockButton color={btnColor}>
        <Icon color="white">
          <Lock2 size="sm" />
        </Icon>
      </LockButton>
    )
  }

  if (status === 'locked') {
    return (
      <LockButton color={btnColor}>
        <Icon color="white">
          <Lock2 size="sm" />
        </Icon>
      </LockButton>
    )
  }

  return (
    <Button color="success" narrow onClick={() => openAI()}>
      <FormattedMessage id="Start" />
    </Button>
  )
}

const AITutorLink: React.FC<AITutorLinkProps> = ({ unit, status, txtColor = 'white', btnColor = 'brandPrimary' }) => (
  <AIWrapper>
    {unit?.AITutorLink && (
      <>
        <Subtitle color={txtColor}>
          <FormattedMessage id="Practice with your AI Tutor" />
        </Subtitle>

        <Spacer size="xs" />

        <LockButtonWrapper status={status} unit={unit} btnColor={btnColor} />

        <Spacer size="xs" />
      </>
    )}
  </AIWrapper>
)

export default AITutorLink
