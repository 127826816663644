import Expand6 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/52-Expand-Retract/Expand6'
import Button from '@components/Button'
import styled, { css } from 'styled-components'

import { IPAlign, IPCard, IPColor, IPIconCircle } from './interface'

export const CenteredDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BtnWithIcon = styled(Button)`
  padding-left: ${({ theme }) => theme.space.md};
  padding-right: ${({ theme }) => theme.space.md};
`

export const BtnContentCtr = styled.div`
  display: inline-flex;
`

export const StyledExpand = styled(Expand6)`
  font-size: ${({ theme }) => theme.fontSize.xs};
  color: ${({ theme }) => theme.palette.charcoal};
  padding-left: ${({ theme }) => theme.space.xs};
`

export const InfoList = styled.div<IPAlign>`
  display: flex;
  flex-direction: column;
  width: 170px;

  ${({ align = 'left' }) => css`
    text-align: ${align};
  `}
`

export const InfoCard = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.sm}`};

  .info-title {
    font-size: ${({ theme }) => theme.fontSize.xs};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.56px;
    text-align: center;
  }
`

export const Float = styled.div`
  width: max-content;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  align-self: inherit;
  border-radius: 4px;
`

export const SelectorTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  align-self: baseline;
`

export const LinkButton = styled.div`
  text-decoration: underline;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${({ theme }) => theme.palette.white};
  display: flex;
  align-items: center;

  svg {
    font-size: ${({ theme }) => theme.fontSize.xs};
    color: ${({ theme }) => theme.palette.white};
    margin-left: ${({ theme }) => theme.space.xxs};
  }
`

export const Subtitle = styled.div<IPColor>`
  color: ${({ theme, color = 'white' }) => theme.palette[color]};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.div<IPColor>`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.63px;
  text-align: center;
  color: ${({ theme, color = 'white' }) => theme.palette[color]};

  .lowercase {
    text-transform: lowercase;
  }
`

export const Tag = styled.div<IPColor>`
  width: max-content;
  border-radius: ${({ theme }) => theme.radius.sm};
  padding: ${({ theme }) => `3px ${theme.space.xxs}`};
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  text-align: center;

  ${({ theme, color = 'white', textColor = 'charcoal' }) => css`
    background-color: ${theme.palette[color]};
    color: ${theme.palette[textColor]};
  `}
`

export const IconCircle = styled.div<IPIconCircle>`
  width: 100%;
  height: 100%;
  position: absolute;

  ${({ theme, color = 'white', opacity = '1' }) => css`
    background-color: ${theme.palette[color]};
    opacity: ${opacity};
  `}
`

export const Icon = styled.div<IPColor>`
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 200px;
  overflow: hidden;
  position: relative;

  svg {
    z-index: 1;

    ${({ theme, color = 'white' }) => css`
      color: ${theme.palette[color]};
    `}
  }
`

export const CardContent = styled.div<{ isElective?: boolean }>`
  width: 230px;
  height: 386px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 13px;
  ${({ isElective }) =>
    isElective &&
    `
    padding-top: 29px;
  `}
`
const withHaloCSS = (secondBG, position, bgSize) => css`
  background-image: url(/images/circles.svg), url(${secondBG});
  background-position:
    left -26px top -100px,
    ${position};
  background-size: 280px, ${bgSize};
`

const withConfettiCSS = (secondBG, position, bgSize) => css`
  background-image: url(/images/confetti.svg), url(${secondBG});
  background-position:
    left 47px top 2px,
    ${position};
  background-size: 201px, ${bgSize};
`

export const Card = styled.div<IPCard>`
  border-radius: 12px;
  position: relative;
  width: 230px;
  min-height: 386px;

  ${({ active }) =>
    active &&
    css`
      box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
    `}

  ${({ theme, type, withHalo, completed }) => {
    switch (type) {
      case 'speakPrac':
        return css`
          background-color: ${theme.palette.info100};
          background-image: url('/images/conversation.svg');
          background-repeat: no-repeat;
          background-position: bottom;
          background-position: left -16px bottom -10px;
          background-size: 276px;

          ${Title} {
            color: ${theme.colors.brandPrimaryContrast};
          }

          ${SelectorTitle} {
            color: ${theme.colors.brandPrimaryContrast};
          }

          ${withHalo && withHaloCSS('/images/conversation.svg', 'left -18px bottom -10px', '276px')}

          ${completed &&
          css`
            background-image: url('/images/celebration-2.svg');
            background-size: inherit;
            background-position: bottom;
            background-position: left 16px bottom -20px;
          `}
        `

      case 'checkpoint':
        return css`
          background-color: ${theme.palette.purple100};
          background-image: url('/images/review.svg');
          background-repeat: no-repeat;
          background-position: bottom;
          background-position: left 0px bottom -36px;
          background-size: 245px;

          ${withHalo && withHaloCSS('/images/review.svg', 'left 0px bottom -36px', '245px')}

          ${completed &&
          css`
            background-image: url('/images/celebration-2.svg');
            background-size: inherit;
            background-position: bottom;
            background-position: left 16px bottom -20px;
          `}
        `
      case 'lcs0':
        return css`
          background-color: ${theme.palette.purple100};
          background-image: url('/images/conversation.svg');
          background-repeat: no-repeat;
          background-position: bottom;
          background-position: left -16px bottom -10px;
          background-size: 276px;

          ${Title} {
            color: ${theme.colors.brandPrimaryContrast};
          }

          ${SelectorTitle} {
            color: ${theme.colors.brandPrimaryContrast};
          }

          ${withHalo && withHaloCSS('/images/conversation.svg', 'left -18px bottom -10px', '276px')}
        `
      case 'lesson0':
        return css`
          background-color: ${theme.palette.blue100};
          background-image: url('/images/onboarding.svg');
          background-repeat: no-repeat;
          background-position: bottom;
          background-position: left 0px bottom 0px;
          background-size: 230px;

          ${Title} {
            color: ${theme.colors.brandPrimaryContrast};
          }

          ${SelectorTitle} {
            color: ${theme.colors.brandPrimaryContrast};
          }

          ${withHalo && withHaloCSS('/images/onboarding.svg', 'left 0px bottom 0px', '230px')}
        `
      default:
        css``
    }
  }}

  ${({ theme, type, withConfetti, completed }) =>
    type === 'speakingPanel' &&
    css`
      background-color: ${theme.palette.info20};
      background-image: url('/images/conversation.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-position: left -16px bottom -10px;

      ${completed &&
      css`
        background-color: ${theme.palette.info100};
        ${withConfetti && withConfettiCSS('/images/celebration-2.svg', 'left 31px bottom -24px', '206px')}
      `}
    `}

  ${({ theme, type, withHalo }) =>
    type === 'culture' &&
    css`
      background-color: ${theme.palette.purple100};
      background-image: url('/images/undraw-quiz-nlyh.svg');
      background-repeat: no-repeat;
      background-position: bottom;

      ${withHalo && withHaloCSS('/images/undraw-quiz-nlyh.svg', 'left -18px bottom -10px', 'initial')}
    `}

  ${({ theme, type, withHalo }) =>
    type === 'review' &&
    css`
      background-color: ${theme.palette.warning100};
      background-image: url('/images/reading.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-position: left -45px bottom -46px;
      background-size: 320px;

      ${withHalo && withHaloCSS('/images/reading.svg', 'left -45px bottom -46px', '320px')}
    `}

  ${({ float }) =>
    float &&
    css`
      box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2);
    `}

  ${({ theme, disabled, withHalo, completed }) =>
    disabled &&
    css`
      box-shadow: none;
      box-sizing: border-box;
      background-color: ${theme.palette.blueGray10} !important;
      background-image: url('/images/conversation.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      background-position: left -16px bottom -10px;
      background-size: 276px;

      ${CardContent} {
        border-radius: 12px;
        border: 2px solid ${theme.palette.blueGray20};
      }

      ${Icon} {
        background-color: ${theme.palette.white};

        svg {
          color: ${theme.palette.danger100};
        }
      }

      ${Title} {
        color: ${theme.palette.blueGray40};
      }

      ${SelectorTitle}, .ant-select-selection--single {
        color: ${theme.palette.blueGray40};

        svg {
          color: ${theme.palette.blueGray40};
        }
      }

      .sub-container {
        background-color: ${theme.palette.gray40};

        svg {
          color: ${theme.palette.blueGray40};
        }
      }

      ${withHalo && withHaloCSS('/images/conversation.svg', 'left -18px bottom -10px', '276px')}

      ${completed &&
      css`
        background-image: url('/images/celebration-2.svg');
        background-size: inherit;
        background-position: bottom;
        background-position: left 16px bottom -20px;
      `}
    `}}
`

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

export const CardHeaderCol = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
