import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Float } from '@components/LPCard/Common'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPUnit, IPUrl } from './interface'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import AITutorLink from '../Common/AITutorLink'

const InProgress: React.FC<IPUrl & IPUnit> = ({ url, unit }) => {
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  return (
    <>
      <Spacer size="sm" />
      <Float>
        <Button
          disabled={!url}
          color="success"
          narrow
          onClick={() => {
            if (state.allowed) {
              return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', url)
            }

            window.open(url, '_self')
          }}
        >
          <FormattedMessage id="Continue" />
        </Button>
      </Float>
      <Spacer size="xl" />
      <AITutorLink unit={unit} status="locked" txtColor="white" btnColor="brandSecondary" />
    </>
  )
}

export default InProgress
