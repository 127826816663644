import Spacer from '@berlitz/spacer'
import LockUnlock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/LockUnlock1'
import Button from '@components/Button'
import {
  BtnContentCtr,
  BtnWithIcon,
  Card,
  CardContent,
  CardHeader,
  CenteredDiv,
  Float,
  Icon,
  IconCircle,
  LinkButton,
  StyledExpand,
  Subtitle,
  Tag,
  Title,
} from '@components/LPCard/Common'
import { IPSpeakingPractice } from '@components/LPCard/SpeakingPractice/interface'
import { LP_JAPAN_BOOKING_URL } from '@utils/constants/learningPath'
import Router from 'next/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { TopTag } from '../Lesson/style'
import BookForm from './BookForm'
import WhyModal from './WhyModal'
import PortalLearningPath from '@classes/LearningPath'

interface Props extends IPSpeakingPractice {
  withBookBtn?: boolean
  Path?: PortalLearningPath
}

export const Unlocked: React.FC<Props> = ({ withBookBtn, Unit, Path }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const [flipped, setFlipped] = React.useState(false)

  const bookable =
    !withBookBtn &&
    !Unit?.HasOngoingSession &&
    (!Unit?.Session || Unit?.UnlimitedLCS) &&
    !Unit?.HasContractEnded &&
    Path?.IsActiveFlexRegistration
  return (
    <>
      {!flipped ? (
        <Card type="speakPrac" withHalo>
          <WhyModal open={modalOpen} onClose={() => setModalOpen(false)} />

          <CardContent isElective={Unit?.IsFromElective}>
            <CardHeader>
              {Unit?.IsFromElective && (
                <TopTag color="blue100">
                  <FormattedMessage id="Elective" defaultMessage="Elective" />
                </TopTag>
              )}
              <Icon color="info100">
                <IconCircle color="white" />
                <LockUnlock1 />
              </Icon>
            </CardHeader>

            <Spacer size="md" />

            <Tag>
              <FormattedMessage id="Unlocked" />
            </Tag>

            <Spacer size="xs" />

            <Title>{Unit?.LCSTitle}</Title>

            <Spacer size="sm" />

            <Subtitle>
              <LinkButton onClick={() => setModalOpen(true)}>
                <FormattedMessage id="Why do I have to book?" />
              </LinkButton>

              <Spacer size="md" />

              {withBookBtn && !Unit?.HasContractEnded && (
                <Float>
                  <Button color="success" narrow onClick={() => setFlipped(!flipped)}>
                    <FormattedMessage id="Book" />
                  </Button>
                </Float>
              )}
              {bookable && !Unit?.IsJapanFlex && (
                <Float>
                  <Button
                    color="success"
                    narrow
                    onClick={() =>
                      Router.push(
                        `/scheduling/single-lesson?programid=${Unit?.StudentProgram?.ProgramId}&unitId=${Unit?.Id}&LPId=${Unit?.LPId}`
                      )
                    }
                  >
                    <FormattedMessage id="Book" />
                  </Button>
                </Float>
              )}

              {Unit?.IsJapanFlex && (
                <Float>
                  <BtnWithIcon color="success" narrow onClick={() => window.open(LP_JAPAN_BOOKING_URL)}>
                    <BtnContentCtr>
                      <CenteredDiv>
                        <FormattedMessage id="Book" />
                      </CenteredDiv>
                      <StyledExpand></StyledExpand>
                    </BtnContentCtr>
                  </BtnWithIcon>
                </Float>
              )}
            </Subtitle>
            <Spacer size="xs" />
          </CardContent>
        </Card>
      ) : (
        <BookForm />
      )}
    </>
  )
}

export default Unlocked
