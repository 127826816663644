import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import Checkbox from '@components/Checkbox'
import { CardModal } from '@components/LPCard/Common'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'

const CheckContainer = styled.div`
  width: max-content;
  margin: auto;

  .max-checkbox {
    width: max-content;
  }
`

interface IPRedoModal {
  open: boolean
  onClose: Function
  onRedo: (dontShow: boolean) => void
}

const RedoModal: React.FC<IPRedoModal> = ({ open, onClose, onRedo }) => {
  const [dontShow, setDontShow] = React.useState(false)

  return (
    <CardModal open={open} onClose={onClose} withBackdrop>
      <CardModal.Title>
        <FormattedMessage id="Practice makes perfect" />
      </CardModal.Title>

      <CardModal.Desc>
        <FormattedMessage id="Redo your lesson as many times as you like!" />

        <Spacer size="md" />

        <Button narrow onClick={() => onRedo(dontShow)}>
          <FormattedMessage id="Redo" />
        </Button>

        <Spacer size="sm" />

        <CheckContainer>
          <Checkbox
            checked={dontShow}
            label={<FormattedMessage id="Don't show again" />}
            name=""
            value=""
            field={{
              name: '',
            }}
            onChange={() => setDontShow(!dontShow)}
          />
        </CheckContainer>
      </CardModal.Desc>
    </CardModal>
  )
}

export default RedoModal
