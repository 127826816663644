import styled, { css } from 'styled-components'

import { IPStatus } from './interface'

export const Icon = styled.div<IPStatus>`
  border: 1px solid;
  width: max-content;
  padding: ${({ theme }) => theme.space.xxxs};
  border-radius: 200px;
  background-color: ${({ theme }) => theme.palette.white};
  position: absolute;
  right: -4px;
  top: -7px;

  svg {
    font-size: 10px;

    ${({ theme, status }) =>
      status === 'success' &&
      css`
        color: ${theme.palette.success100};
      `}

    &_::-webkit-full-page-media, _:future, :root .safari_only {
      width: 16px;
    }

    @media screen and (min-color-index: 0) and(-webkit-min-device-pixel-ratio:0) {
      @media {
        width: 16px;
      }
    }

    @media not all and (min-resolution: 0.001dpcm) {
      @media {
        width: 16px;
      }
    }
  }
`

export const Wrapper = styled.div<IPStatus>`
  position: relative;
  padding: ${({ theme }) => `7px ${theme.space.xs} 6px`};
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.33);
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.white};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: 0.56px;
  text-align: center;
  background-color: ${({ theme }) => theme.palette.white};

  ${({ theme, status }) => css`
    ${status === 'success' && `background-color: ${theme.palette.success100};`}
    ${status === 'in-progress' && `background-color: ${theme.palette.warning100};`}
    ${status !== 'success' && status !== 'in-progress' && `color: ${theme.palette.charcoal};`}
  `}
`
