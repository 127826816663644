import * as React from 'react'
import { FormattedMessage, injectIntl, InjectedIntlProps } from 'react-intl'
import Spacer from '@berlitz/spacer'

import Lesson from '@components/LPCard/Lesson'
import Datepicker from '@components/Datepicker'
import Button from '@components/Button'
import { FormWrapper } from './style'

const BookForm: React.FC<InjectedIntlProps> = ({ intl }) => {
  const [date, setDate] = React.useState(null)
  const [time, setTime] = React.useState(null)

  return (
    <Lesson status="default" title={intl.formatMessage({ id: 'Speaking practice' })}>
      <Spacer size="md" />

      <FormWrapper>
        <Datepicker
          label={intl.formatMessage({ id: 'Date' })}
          dateFormat="dd MMMM yyyy"
          minDate={new Date()}
          openToDate={new Date()}
          onChange={(date) => setDate(date)}
        />

        <Datepicker
          label={intl.formatMessage({ id: 'Time' })}
          showTimeSelect
          showTimeSelectOnly
          timeIntervals={15}
          dateFormat="h:mm aa"
          timeCaption="Time"
          placeholderText={intl.formatMessage({ id: 'Select time' })}
          minDate={new Date()}
          onChange={(time) => setTime(time)}
        />

        <Button disabled={!date || !time}>
          <FormattedMessage id="Book" />
        </Button>
      </FormWrapper>
    </Lesson>
  )
}

export default injectIntl(BookForm)
