import Checkpoint from '@components/LPCard/Checkpoint'
import Culture from '@components/LPCard/Culture'
import Lesson from '@components/LPCard/Lesson'
import Review from '@components/LPCard/Review'
import SpeakingPractice from '@components/LPCard/SpeakingPractice'
import * as React from 'react'

import { Card, CardContent } from './Common'

class LPCard extends React.PureComponent {
  static SpeakingPractice = SpeakingPractice
  static Lesson = Lesson
  static Checkpoint = Checkpoint
  static Culture = Culture
  static Review = Review

  render() {
    const { children } = this.props
    return (
      <Card type="default">
        <CardContent>{children}</CardContent>
      </Card>
    )
  }
}

export { SpeakingPractice, Lesson, Checkpoint, Culture, Review }
export default LPCard
