import { Tick } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import Expand6 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/52-Expand-Retract/Expand6'
import LearningPathUnit from '@classes/LearningPathUnit'
import Button from '@components/Button'
import {
  Card,
  CardContent,
  CardHeader,
  Float,
  Icon,
  IconCircle,
  InfoCard,
  InfoList,
  LinkButton,
  Rating,
  Stars,
  Tag,
  Title,
} from '@components/LPCard/Common'
import { isCompleted } from '@components/LPCard/Common/Rating'
import { IPSpeakingPractice } from '@components/LPCard/SpeakingPractice/interface'
import Router from 'next/router'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { TopTag } from '../Lesson/style'
import { RatingWrapper } from './style'

export const Completed: React.FC<{ Unit: LearningPathUnit; InDashboard?: boolean } & IPSpeakingPractice> = ({
  Unit,
  InDashboard = false,
}) => {
  const isSuccess = isCompleted(Unit?.Session?.Grade ?? 0) ? 'success' : ''
  const isLCSZero = InDashboard ? Unit.IsLCSZero && !Unit.HasContractEnded : Unit.IsLCSZero
  const UnitLCSTitle = InDashboard && Unit.HasContractEnded ? 'Live coaching session' : Unit.LCSTitle
  const displayedSession = Unit.UnlimitedLCS
    ? Unit.Session?.Materials?.length
      ? Unit.Session
      : Unit?.SubmittedSession || Unit.MatchedSession || Unit?.Session
    : Unit.MatchedSession || Unit?.Session

  const body = () => (
    <>
      {displayedSession?.lesson?.Materials?.length ? (
        <RatingWrapper>
          <Rating rating={displayedSession?.Grade ?? 0} />
          <div style={{ marginLeft: '10px' }}>
            <Stars stars={Math.ceil((displayedSession?.Grade ?? 0) / 20)} />
          </div>
        </RatingWrapper>
      ) : (
        <Float>
          <InfoCard>
            <InfoList align="center" className="info-title">
              <FormattedMessage id="Instructor feedback in progress" />
            </InfoList>
          </InfoCard>
        </Float>
      )}

      <Spacer size="xs" />

      <LinkButton onClick={() => Router.push('/my-media')}>
        <FormattedMessage id="Watch lesson" />
        <Expand6 />
      </LinkButton>
      <Spacer size="xs" />
    </>
  )
  return (
    <Card type={isLCSZero ? 'lcs0' : 'speakPrac'} completed={isSuccess === 'success'}>
      <CardContent isElective={Unit.IsFromElective}>
        <CardHeader>
          {Unit?.IsFromElective && (
            <TopTag color="blue100">
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </TopTag>
          )}
          <Icon color="white">
            <IconCircle color="white" opacity="0.27" />
            <Tick />
          </Icon>
        </CardHeader>

        <Spacer size="xxxs" />
        <Spacer size="sm" />

        <Tag>
          <FormattedMessage id="Completed" />
        </Tag>

        <Spacer size="xxxs" />
        <Spacer size="xs" />

        <Title>{UnitLCSTitle}</Title>

        <Spacer size="sm" />

        {!isLCSZero && body()}

        {!Unit.DisableLCS && Unit?.UnlimitedLCS && !Unit?.IsBooked && !Unit?.HasContractEnded && (
          <Float>
            <Button
              color="green"
              narrow
              onClick={() =>
                Router.push(
                  `/scheduling/single-lesson?programid=${Unit?.StudentProgram?.ProgramId}&unitId=${Unit?.Id}&LPId=${Unit?.LPId}`
                )
              }
            >
              <FormattedMessage id="Redo" />
            </Button>
          </Float>
        )}
      </CardContent>
    </Card>
  )
}

export default Completed
