import styled, { css } from 'styled-components'

import { IHeader, IPColor, IPStatus } from './interface'

export const LessonDesc = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.63px;
  text-align: center;
`

export const Lesson0Desc = styled.div`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.22;
  letter-spacing: 0.63px;
  text-align: center;
  color: ${({ theme }) => theme.palette.white};
`

export const LessonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 248px;

  .status-tag {
    margin-top: ${({ theme }) => theme.space.xs};
  }
`

export const AIWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 86px;
`

export const Lesson0Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 386px;
  padding-top: 8px;

  .status-tag {
    margin-top: ${({ theme }) => theme.space.xs};
  }
`

export const LessonOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #3a3a3ac7;
  z-index: 1;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
`

export const Card = styled.div`
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  min-width: 195px;
  max-width: 195px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export const Header = styled.div<IHeader>`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.sm}`};
  background-color: ${({ theme }) => theme.palette.info10};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  justify-content: center;

  p {
    margin-left: ${({ theme }) => theme.space.sm};
  }
`

export const CleanHeader = styled.div`
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  padding: ${({ theme }) => `${theme.space.xs} ${theme.space.sm}`};
  display: flex;
  align-items: center;
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  justify-content: center;

  p {
    margin-left: ${({ theme }) => theme.space.sm};
  }
`

export const ModalWrapper = styled.div`
  div:first-child {
    margin-top: -190px;
  }
`

export const Wrapper = styled.div<IPStatus>`
  border: 1px solid;
  border-radius: 12px;
  width: 230px;
  min-height: 386px;
  position: relative;

  ${({ theme, status }) =>
    status === 'default' &&
    css`
      border: 2px solid ${theme.palette.info30};
      background-color: ${theme.palette.info20};

      ${Header} {
        color: ${theme.palette.info100};
      }
    `}

  ${({ theme, status }) =>
    status === 'start' &&
    css`
      border: 2px solid ${theme.palette.blue30};
      background-color: ${theme.palette.blue20};

      ${Header} {
        color: ${theme.palette.blue100};
        background-color: ${({ theme }) => theme.palette.blue20};
      }
    `}

  ${({ theme, status }) =>
    status === 'in-progress' &&
    css`
      border: 2px solid ${theme.palette.purple30};
      background-color: ${theme.palette.purple20};

      ${Header} {
        color: ${theme.palette.purple100};
        background-color: ${({ theme }) => theme.palette.purple20};
      }
    `}

  ${({ theme, status }) =>
    status === 'completed' &&
    css`
      border: 2px solid ${theme.palette.green30};
      background-color: ${theme.palette.green20};

      ${Header} {
        color: ${theme.palette.green100};
        background-color: ${({ theme }) => theme.palette.green20};
      }
    `}

  ${({ theme, status }) =>
    status === 'loading' &&
    css`
      border: 2px solid ${theme.palette.info30};
      background-color: ${theme.palette.info20};

      ${Header} {
        color: ${theme.palette.info100};
      }
    `}

  & > div:last-child {
    padding-right: 15px;
    padding-left: 15px;
  }

  .content-loaders {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    margin: auto;
    margin-top: 25px;

    .loader-item {
      margin-right: 8px;
      margin-bottom: 12px;
    }
  }

  ${({ isElective }) =>
    isElective &&
    `
    ${Header} {
      padding-top: 29px;
    }

    ${LessonWrapper} {
      height: 234px;
    }
  `}
`

export const TagContainer = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-left: ${({ theme }) => theme.space.xxxs};
    margin-right: ${({ theme }) => theme.space.xxxs};
  }
`

export const SideTag = styled.span<{ color: string; textColor?: string }>`
  position: absolute;
  left: 0;
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  color: ${({ theme, textColor }) => (textColor && theme.palette[textColor]) || textColor || theme.palette.white};
  line-height: 1.33;
  padding: 2px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-right: 8px;
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: ${({ theme, color = 'white' }) => theme.palette[color] || color};
`

export const TopTag = styled.span<{ color: string; textColor?: string }>`
  ${({ theme, textColor, color = 'white' }) => `
    position: absolute;
    top: 0;
    font-size: ${theme.fontSize.xxxs};
    color: ${(textColor && theme.palette[textColor]) || textColor || theme.palette.white};
    line-height: 1.33;
    padding: ${theme.space.xxxs} 6px;
    border-bottom-left-radius: ${theme.space.xxs};
    border-bottom-right-radius: ${theme.space.xxs};
    font-weight: ${theme.fontWeight.bold};
    background-color: ${theme.palette[color] || color};
    letter-spacing: 0.42px;
  `}
`

export const Subtitle = styled.div<IPColor>`
  color: ${({ theme, color = 'white' }) => theme.palette[color]};
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: 0.49px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`
