import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { CardModal } from '@components/LPCard/Common'

import { IPModal } from './interface'

const WhatModal: React.FC<IPModal> = ({ open, onClose }) => {
  return (
    <CardModal open={open} onClose={onClose}>
      <CardModal.Title>
        <FormattedMessage id="What is a live coaching session?" />
      </CardModal.Title>
      <CardModal.Desc>
        <FormattedMessage id="A live coaching session is a chance for you to practice what you've learnt in your lessons with a Berlitz instructor, online via video call. A coaching session is 30 minutes long." />
      </CardModal.Desc>
      <CardModal.CloseBtn onClick={() => onClose()}>
        <FormattedMessage id="Tap to close" />
      </CardModal.CloseBtn>
    </CardModal>
  )
}

export default WhatModal
