import Spacer from '@berlitz/spacer'
import TravelLuggage1 from '@berlitz/streamline-icons/lib/regular/23-Travel/01-Travel-Metaphor/TravelLuggage1'
import Button from '@components/Button'
import { Card, CardContent, Float, Icon, IconCircle, Tag, Title } from '@components/LPCard/Common'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import PortalLearningPathUnit from '@classes/LearningPathUnit'

interface IPCulture {
  withHalo?: boolean
  title: React.ReactNode
  url?: string
  active?: boolean
  unit?: PortalLearningPathUnit
}

const Culture: React.FC<IPCulture> = ({ withHalo, title, url = '', active, unit }) => {
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  return (
    <Card type="culture" withHalo={withHalo} active={active}>
      <CardContent>
        <Icon color="white">
          <IconCircle color="white" opacity="0.27" />
          <TravelLuggage1 />
        </Icon>

        <Spacer size="md" />

        <Tag>
          <FormattedMessage id="Culture" />
        </Tag>

        <Spacer size="xs" />

        <Title>{title}</Title>

        <Spacer size="md" />

        {!unit?.HasContractEnded && (
          <Float>
            <Button
              disabled={!url}
              color="success"
              narrow
              onClick={() => {
                if (state.allowed) {
                  return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', url)
                }

                window.open(url, '_self')
              }}
            >
              <FormattedMessage id="Discover" />
            </Button>
          </Float>
        )}
      </CardContent>
    </Card>
  )
}

export default Culture
