import Spacer from '@berlitz/spacer'
import LockUnlock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/LockUnlock1'
import Compass1 from '@berlitz/streamline-icons/lib/regular/48-Maps-Navigation/01-Compass/Compass1'
import LearningPathUnit from '@classes/LearningPathUnit'
import Button from '@components/Button'
import { Card, CardContent, Float, Icon, IconCircle, Subtitle, Tag, Title } from '@components/LPCard/Common'
import Router from 'next/router'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import InfoCard from './InfoCard'
import PortalLearningPath from '@classes/LearningPath'
import { AIWrapper, AISubtitle } from './style'
import AITutorLink from '../Common/AITutorLink'

interface IPUnlocked {
  Unit: LearningPathUnit
  Path?: PortalLearningPath
}
export const Unlocked: React.FC<IPUnlocked> = ({ Unit, Path }) => {
  const isLCSZero = Unit.IsLCSZero

  if (isLCSZero) {
    return (
      <Card type="lcs0" withHalo={true}>
        <CardContent>
          <Icon color="purple100">
            <IconCircle color="white" />
            <Compass1 />
          </Icon>

          <Spacer size="md" />

          <Title>{Unit?.LCSTitle}</Title>

          <Spacer size="sm" />

          <Subtitle>
            <FormattedMessage
              id="This 25 minute video call with an instructor will help you get started with Berlitz Flex."
              defaultMessage="This 25 minute video call with an instructor will help you get started with Berlitz Flex."
            />
            <Spacer size="sm" />
            {!Unit.IsBooked && !Unit.HasContractEnded && Path?.IsActiveFlexRegistration && (
              <Float>
                <Button
                  color="success"
                  narrow
                  onClick={() =>
                    Router.push(
                      `/scheduling/single-lesson?programid=${Unit?.StudentProgram?.ProgramId}&unitId=0000&LPId=${Unit?.LPId}`
                    )
                  }
                >
                  <FormattedMessage id="Book" />
                </Button>
              </Float>
            )}
          </Subtitle>

          <Spacer size="lg" />

          <AITutorLink unit={Unit} status="locked" txtColor="white" btnColor="brandSecondary" />
        </CardContent>
      </Card>
    )
  } else {
    return (
      <Card type="speakPrac">
        <CardContent>
          <Icon color="white">
            <IconCircle color="white" opacity="0.27" />
            <LockUnlock1 />
          </Icon>

          <Spacer size="md" />

          <Tag>
            <FormattedMessage id="Unlocked" />
          </Tag>

          <Spacer size="xs" />

          <Title>{Unit?.LCSTitle}</Title>

          <Spacer size="sm" />

          <Subtitle>
            {!Unit.HasContractEnded && Path?.IsActiveFlexRegistration && (
              <Float>
                <Button
                  color="success"
                  narrow
                  onClick={() =>
                    Router.push(
                      `/scheduling/single-lesson?programid=${Unit?.StudentProgram?.ProgramId}&unitId=0000&LPId=${Unit?.LPId}`
                    )
                  }
                >
                  <FormattedMessage id="Book" />
                </Button>
              </Float>
            )}
          </Subtitle>

          <Spacer size="md" />

          <InfoCard />
          <Spacer size="xs" />
        </CardContent>
      </Card>
    )
  }
}

export default Unlocked
