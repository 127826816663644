import Spacer from '@berlitz/spacer'
import TimeClockCircle from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/18-Time/TimeClockCircle'
import Calendar1 from '@berlitz/streamline-icons/lib/regular/01-Interface-Essential/21-Date-Calendar/Calendar1'
import Button from '@components/Button'
import {
  Card,
  CardContent,
  CardHeader,
  Float,
  Icon,
  IconCircle,
  InfoCard,
  InfoList,
  Tag,
  Title,
} from '@components/LPCard/Common'
import WithUserTime, { WithUserTimeProps } from '@hocs/WithUserTime'
import { DATE_FORMAT } from '@utils/constants'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { SideTag } from '../Lesson/style'
import { IPBooked } from './interface'
import { DateTimeBox } from './style'

export const Booked: React.FC<IPBooked & WithUserTimeProps> = ({
  getUserDate,
  getDateWithUserTimezone,
  startTime,
  Unit,
}) => {
  const startTimeTZ = getDateWithUserTimezone(startTime?.format(DATE_FORMAT.DT))
  const inProgress = getUserDate().isAfter(startTimeTZ)

  return (
    <Card type="speakPrac" withHalo={inProgress}>
      <CardContent>
        <CardHeader>
          {Unit?.IsFromElective && (
            <SideTag color="blue100">
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </SideTag>
          )}
          <Icon color={inProgress ? 'charcoal' : 'info100'}>
            <IconCircle color={inProgress ? 'green100' : 'white'} />
            <Calendar1 />
          </Icon>
        </CardHeader>

        <Spacer size="md" />

        <Tag>
          <FormattedMessage id="Booked" />
        </Tag>

        <Spacer size="xs" />

        <Title>
          <FormattedMessage id="Live coaching session" />
        </Title>

        <Spacer size="sm" />

        <Float>
          <InfoCard>
            <InfoList>
              <DateTimeBox>
                <Calendar1 />
                {startTimeTZ.format('Do MMMM YYYY')}
              </DateTimeBox>
              <Spacer size="xs" />

              <DateTimeBox>
                <TimeClockCircle />
                {startTimeTZ.format('HH:mm')}
              </DateTimeBox>
              <Spacer size="sm" />

              <div>
                <Button color="success" narrow fullWidth disabled={!inProgress}>
                  <FormattedMessage id="Join" />
                </Button>
                <Spacer size="xxs" />
              </div>
            </InfoList>
          </InfoCard>
        </Float>

        <Spacer size="xs" />
      </CardContent>
    </Card>
  )
}

export default WithUserTime(Booked)
