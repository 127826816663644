import { Tick } from '@berlitz/icons'
import { LMSLearningPlansRounds } from '@interfaces/LearningPath'
import { LP_STATUS } from '@utils/constants/learningPath'
import chunk from 'lodash/chunk'
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import React from 'react'
import { ActivityContainer, Container } from './style'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import PortalLearningPathUnit from '@classes/LearningPathUnit'

interface Props {
  activities: LMSLearningPlansRounds[]
  unit?: PortalLearningPathUnit
  disabled?: boolean
}

const ActivityRounds: React.FC<Props> = ({ activities = [], unit, disabled = false }) => {
  const [currActivity, setCurrActivity] = React.useState<LMSLearningPlansRounds | null>(null)
  const chunkedActivities = chunk(activities, 7)
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  if (!activities.length) {
    null
  }

  return (
    <Container>
      {chunkedActivities.map((chunks, index) => (
        <div key={`chunk-${index}`}>
          <ActivityContainer disabled={disabled} className="act-con">
            <div className="line" />
            {orderBy(chunks, (item) => parseInt(item.order, 10)).map((activity, order) => {
              const { status, url } = activity
              const isSelected = isEqual(currActivity, activity)
              const selected = isSelected ? null : activity
              return (
                <div
                  key={order}
                  className={`icon-circle ${status}-circle ${isSelected && 'selected'} ${
                    status === LP_STATUS.COMPLETE ? '' : 'cursor'
                  }`}
                  onClick={() => {
                    if (disabled) {
                      return false
                    }

                    setCurrActivity(selected)

                    if (state.allowed && status !== LP_STATUS.COMPLETE) {
                      return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', url, activities)
                    }

                    url && status !== LP_STATUS.COMPLETE && window.open(url, '_self')
                  }}
                >
                  {status === LP_STATUS.COMPLETE && (
                    <div className="tick">
                      <Tick />
                    </div>
                  )}
                </div>
              )
            })}
          </ActivityContainer>
        </div>
      ))}
    </Container>
  )
}

export default ActivityRounds
