import Spacer from '@berlitz/spacer'
import Lock2 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock2'
import LearningPathUnit from '@classes/LearningPathUnit'
import { Card, CardContent, Icon, IconCircle, Subtitle, Tag, Title } from '@components/LPCard/Common'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import InfoCard from './InfoCard'
import { SubContainer } from './style'

export const Locked: React.FC<{ Unit?: LearningPathUnit }> = ({ Unit }) => (
  <Card type="speakPrac">
    <CardContent>
      <Icon color="white">
        <IconCircle color="white" opacity="0.27" />
        <Lock2 />
      </Icon>

      <Spacer size="md" />

      <Tag>
        <FormattedMessage id="Locked" />
      </Tag>

      <Spacer size="xs" />

      <Title>
        {Unit?.LCSTitle} <FormattedMessage id="locked" />
      </Title>

      <Spacer size="sm" />

      <SubContainer>
        <Subtitle color="text00">
          <FormattedMessage id="Complete lessons to unlock live coaching sessions." />
        </Subtitle>
      </SubContainer>

      <Spacer size="md" />

      <InfoCard />
    </CardContent>
  </Card>
)

export default Locked
