import Spacer from '@berlitz/spacer'
import AwardTrophyStar1 from '@berlitz/streamline-icons/lib/bold/22-Social-Medias-Rewards-Rating/08-Rewards-Awards/AwardTrophyStar1'
import LearningPathUnit from '@classes/LearningPathUnit'
import { Card, CardContent, Icon, IconCircle, Title } from '@components/LPCard/Common'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SubContainer, SubTitle } from './style'

export const UnlimitedLCS: React.FC<{ Unit?: LearningPathUnit; allBooked?: boolean }> = ({ Unit }) => (
  <Card type="speakingPanel" completed withConfetti active>
    <CardContent>
      <Icon color="white">
        <IconCircle color="white" opacity="0.27" />
        <AwardTrophyStar1 />
      </Icon>

      <Spacer size="md" />

      <Title>
        <FormattedMessage id="Congratulations" />
      </Title>

      <Spacer size="sm" />

      <SubContainer shadowed>
        <Spacer size="sm" />

        <SubTitle color="blue100">
          <FormattedMessage id="You've completed all Live coaching sessions!" />
        </SubTitle>
        <Spacer size="md" />
      </SubContainer>
    </CardContent>
  </Card>
)

export default UnlimitedLCS
