import { Tick, TimeClock } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import LockUnlock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/LockUnlock1'
import CommonFileTextCheck from '@berlitz/streamline-icons/lib/regular/16-Files-Folders/01-Common-Files/CommonFileTextCheck'
import { Card, CardContent, CardHeader, Icon, IconCircle, Tag, Title } from '@components/LPCard/Common'
import { LP_STATUS } from '@utils/constants/learningPath'
import React from 'react'
import { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { TopTag } from '../Lesson/style'
import Completed from './Completed'
import InProgress from './InProgress'
import { IPCheckpoint } from './interface'
import Start from './Start'
import Unlocked from './Unlocked'
import WhatModal from './WhatModal'

const Checkpoint: React.FC<IPCheckpoint> = ({
  status = LP_STATUS.NOT_YET_STARTED,
  float,
  url,
  redo,
  active,
  title,
  unit,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const iconAttr = {
    [LP_STATUS.NOT_YET_STARTED]: {
      icon: <CommonFileTextCheck />,
      color: 'white',
      opacity: '0.27',
      sideTagColor: 'white',
    },
    [LP_STATUS.UNLOCKED]: {
      icon: <LockUnlock1 />,
      color: 'purple100',
      opacity: '1',
      sideTagColor: 'white',
    },
    [LP_STATUS.IN_PROGRESS]: {
      icon: <TimeClock />,
      color: 'purple100',
      opacity: '1',
      sideTagColor: 'white',
    },
    [LP_STATUS.COMPLETE]: {
      icon: <Tick />,
      color: 'white',
      opacity: '0.27',
      sideTagColor: 'white',
    },
  }
  const { icon, color, opacity, sideTagColor } = iconAttr[status] ?? iconAttr[LP_STATUS.NOT_YET_STARTED]
  const isStart = status === LP_STATUS.NOT_YET_STARTED
  const isUnlocked = status === LP_STATUS.UNLOCKED
  const isInprogress = status === LP_STATUS.IN_PROGRESS
  const isCompleted = status === LP_STATUS.COMPLETE
  const withHalo = isUnlocked || isInprogress
  const tagName = isStart ? 'Not started' : isInprogress ? 'In-progress' : 'Completed'
  return (
    <Card type="checkpoint" float={float} withHalo={withHalo} active={active}>
      <WhatModal open={modalOpen} onClose={() => setModalOpen(!modalOpen)} />

      <CardContent isElective={unit?.IsFromElective}>
        <CardHeader>
          {unit?.IsFromElective && (
            <TopTag color={sideTagColor} textColor={'black'}>
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </TopTag>
          )}
          <Icon color={color}>
            <IconCircle color="white" opacity={opacity} />
            {icon}
          </Icon>
        </CardHeader>

        <Spacer size="md" />

        {!isStart && (
          <>
            <Tag>
              <FormattedMessage id={status === LP_STATUS.UNLOCKED ? 'Unlocked' : tagName} />
            </Tag>

            <Spacer size="sm" />
          </>
        )}

        <Title>{title}</Title>

        {isStart && !unit?.HasContractEnded && (
          <Start url={url} unit={unit} toggleModal={() => setModalOpen(!modalOpen)} />
        )}
        {isUnlocked && <Unlocked url={url} unit={unit} toggleModal={() => setModalOpen(!modalOpen)} />}
        {isInprogress && !unit?.HasContractEnded && <InProgress url={url} unit={unit} />}
        {isCompleted && !unit?.HasContractEnded && <Completed url={url} redo={redo} unit={unit} />}
      </CardContent>
    </Card>
  )
}

export default Checkpoint
