import { useMutation } from '@apollo/react-hooks'
import { Tick, TimeClock } from '@berlitz/icons'
import Spacer from '@berlitz/spacer'
import CommonFileTextCheck from '@berlitz/streamline-icons/lib/regular/16-Files-Folders/01-Common-Files/CommonFileTextCheck'
import Button from '@components/Button'
import {
  Card,
  CardContent,
  CardHeader,
  Float,
  Icon,
  IconCircle,
  LinkButton,
  Subtitle,
  Tag,
  Title,
} from '@components/LPCard/Common'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import { RESET_FLEX } from '@mutations/activity'
import { LP_STATUS } from '@utils/constants/learningPath'
import get from 'lodash/get'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { TopTag } from '../Lesson/style'
import { IPReview } from './interface'
import WhatModal from './WhatModal'
import { AIWrapper, AISubtitle } from './style'
import AITutorLink from '../Common/AITutorLink'

const Review: React.FC<IPReview> = ({ status, title, url = '', active, unit }) => {
  const [modalOpen, setModalOpen] = React.useState(false)
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  const iconAttr = {
    [LP_STATUS.NOT_YET_STARTED]: {
      icon: <CommonFileTextCheck />,
      color: 'white',
      opacity: '0.27',
      withHalo: false,
      tagLabel: '',
      tagColor: '',
      buttonLabel: 'Start',
      sideTagColor: '#E99D5C',
    },
    [LP_STATUS.IN_PROGRESS]: {
      icon: <TimeClock />,
      color: 'purple100',
      opacity: '1',
      withHalo: true,
      tagLabel: 'In-progress',
      tagColor: 'purple100',
      buttonLabel: 'Continue',
      sideTagColor: '#E17A17',
    },
    [LP_STATUS.COMPLETE]: {
      icon: <Tick />,
      color: 'white',
      opacity: '0.27',
      withHalo: false,
      tagLabel: 'Completed',
      tagColor: 'blue100',
      buttonLabel: 'Redo',
      sideTagColor: '#E99D5C',
    },
  }
  const isStart = status === LP_STATUS.NOT_YET_STARTED
  const approxMins = 20
  const { icon, color, opacity, withHalo, buttonLabel, tagLabel, tagColor, sideTagColor } = get(
    iconAttr,
    status,
    iconAttr.notyetstarted
  )

  const [resetFlex] = useMutation(RESET_FLEX)

  return (
    <Card type="review" withHalo={withHalo} active={active}>
      <WhatModal open={modalOpen} onClose={setModalOpen} />

      <CardContent isElective={unit?.IsFromElective}>
        <CardHeader>
          {unit?.IsFromElective && (
            <TopTag color={sideTagColor}>
              <FormattedMessage id="Elective" defaultMessage="Elective" />
            </TopTag>
          )}
          <Icon color={color}>
            <IconCircle color="white" opacity={opacity} />
            {icon}
          </Icon>
        </CardHeader>

        <Spacer size="md" />

        {tagLabel && (
          <>
            <Tag color={tagColor} textColor="white">
              <FormattedMessage id={tagLabel} />
            </Tag>
            <Spacer size="xxs" />
          </>
        )}

        <Title>{title}</Title>

        {isStart && (
          <>
            <Spacer size="xs" />

            <Subtitle>
              <FormattedMessage id="Improve your score by reviewing this lesson." />
            </Subtitle>

            <Subtitle>
              <FormattedMessage
                id="(approx. {mins} mins)"
                defaultMessage="(approx. {mins} mins)"
                values={{
                  mins: approxMins,
                }}
              />
            </Subtitle>

            <Spacer size="xxs" />

            <LinkButton onClick={() => setModalOpen(true)}>
              <FormattedMessage id="What is a review?" />
            </LinkButton>
          </>
        )}

        <Spacer size="sm" />
        {!unit?.HasContractEnded && (
          <Float>
            <Button
              disabled={!url}
              color="success"
              narrow
              onClick={() => {
                if (state.allowed) {
                  if (status === LP_STATUS.COMPLETE) {
                    resetFlex({
                      variables: { resetFlex: true },
                    })
                  }
                  return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', url)
                }

                window.open(url, '_self')
              }}
            >
              <FormattedMessage id={buttonLabel} />
            </Button>
          </Float>
        )}

        <Spacer size="xl" />

        <AITutorLink unit={unit} status="locked" txtColor="white" btnColor="brandSecondary" />
      </CardContent>
    </Card>
  )
}

export default Review
