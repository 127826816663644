import { Tick, TimeClock } from '@berlitz/icons'
import Lock2 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock2'
import LockUnlock1 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/LockUnlock1'
import NotesBook from '@berlitz/streamline-icons/lib/regular/11-Content/03-Notes/NotesBook'
import { CircleLoader, RectLoader } from '@components/Loader'
import { Card, Icon, IconCircle } from '@components/LPCard/Common'
import { random } from 'lodash'
import get from 'lodash/get'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Completed from './Completed'
import InProgress from './InProgress'
import { IPLesson } from './interface'
import Locked from './Locked'
import Start from './Start'
import { Header, SideTag, TopTag, Wrapper } from './style'
import WhatModal from './WhatModal'

class Lesson extends React.PureComponent<IPLesson> {
  static Start = Start
  static InProgress = InProgress
  static Completed = Completed
  static Locked = Locked

  render() {
    const {
      children,
      title,
      status,
      float,
      active,
      withHeaderTitle,
      isLessonZero,
      modalOpen = false,
      setModalOpen = () => {},
      language,
      isFromElective = false,
    } = this.props

    const iconAttr = {
      default: {
        icon: <LockUnlock1 />,
        color: 'white',
        bg: 'info100',
      },
      locked: {
        icon: <Lock2 />,
        color: 'white',
        bg: 'info100',
      },
      start: {
        icon: <NotesBook />,
        color: 'white',
        bg: 'blue100',
      },
      'in-progress': {
        icon: <TimeClock />,
        color: 'white',
        bg: 'purple100',
      },
      completed: {
        icon: <Tick />,
        color: 'charcoal',
        bg: 'green100',
      },
      loading: {
        icon: <Tick />,
        color: 'gray20',
        bg: 'gray20',
      },
    }
    const { icon, color, bg } = get(iconAttr, status, iconAttr.default)
    const isLoading = status === 'loading'
    const withHalo = status !== 'completed'

    return isLessonZero ? (
      <Card type="lesson0" float={float} active={active} withHalo={withHalo}>
        <WhatModal open={modalOpen} onClose={() => setModalOpen(false)} language={language} cardTitle={title} />
        {isLoading ? (
          <div className="content-loaders">
            {[...Array(3).keys()].map((_val, index) => (
              <div key={`loader-item-${index}`} className="loader-item">
                <RectLoader width={`${random(55, 100)}px`} height="25px" />
              </div>
            ))}
          </div>
        ) : (
          children
        )}
      </Card>
    ) : (
      <Card type="lesson" float={float} active={active}>
        <Wrapper status={status} active={active} isElective={isFromElective}>
          <Header withHeaderTitle={withHeaderTitle}>
            {isFromElective && !isLoading && (
              <TopTag color={bg}>
                <FormattedMessage id="Elective" defaultMessage="Elective" />
              </TopTag>
            )}
            <Icon color={color}>
              {isLoading ? (
                <CircleLoader width="52px" height="52px" />
              ) : (
                <>
                  <IconCircle color={bg} />
                  {icon}
                </>
              )}
            </Icon>

            {withHeaderTitle && <p>{isLoading ? <RectLoader width={`${random(55, 77)}px`} height="18px" /> : title}</p>}
          </Header>

          {isLoading ? (
            <div className="content-loaders">
              {[...Array(3).keys()].map((_val, index) => (
                <div key={`loader-item-${index}`} className="loader-item">
                  <RectLoader width={`${random(55, 100)}px`} height="25px" />
                </div>
              ))}
            </div>
          ) : (
            children
          )}
        </Wrapper>
      </Card>
    )
  }
}

export default Lesson
