import { CardModal } from '@components/LPCard/Common'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'

import { IPCardModal } from '@components/LPCard/Common/CardModal/interface'

const WhatModal: React.FC<IPCardModal> = ({ open, onClose }) => {
  return (
    <CardModal open={open} onClose={onClose}>
      <CardModal.Title>
        <FormattedMessage id="What is a checkpoint?" />
      </CardModal.Title>
      <CardModal.Desc>
        <FormattedMessage id="The Checkpoint review is a chance to review everything you've learnt recently. You'll test your knowledge and rate your ability." />
      </CardModal.Desc>
      <CardModal.CloseBtn onClick={() => onClose()}>
        <FormattedMessage id="Tap to close" />
      </CardModal.CloseBtn>
    </CardModal>
  )
}

export default WhatModal
