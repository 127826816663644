import * as React from 'react'

import { IPCardModal } from './interface'
import { Wrapper, ModalBG, Content, Title, Desc, CloseBtn } from './style'

class CardModal extends React.PureComponent<IPCardModal> {
  static Title = Title
  static Desc = Desc
  static CloseBtn = CloseBtn

  render() {
    const { open, onClose, children, withBackdrop } = this.props

    if (!open) {
      return null
    }

    return (
      <Wrapper>
        <ModalBG withBackdrop={withBackdrop} onClick={() => onClose(false)} />
        <Content withBackdrop={withBackdrop}>{children}</Content>
      </Wrapper>
    )
  }
}

export default CardModal
