import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import Spacer from '@berlitz/spacer'
import { Lesson } from '@components/LPCard'
import { LessonWrapper, LessonDesc, LessonOverlay, Card } from '@components/LPCard/Lesson/style'
import { Icon, IconCircle, Tag, Title, Subtitle } from '@components/LPCard/Common'
import Lock2 from '@berlitz/streamline-icons/lib/bold/01-Interface-Essential/11-Lock-Unlock/Lock2'
import { IPStart } from './interface'
import AITutorLink from '../Common/AITutorLink'

const Start: React.FC<IPStart> = ({ title, desc, unit }) => {
  return (
    <Lesson status="locked" title={title}>
      <LessonOverlay>
        <Card>
          <Icon color="white">
            <IconCircle color="blueGray40" />
            <Lock2 />
          </Icon>
          <Spacer size="md" />
          <Tag color="blue100" textColor="white">
            <FormattedMessage id="Locked" />
          </Tag>
          <Spacer size="xs" />
          <Title color="charcoal">
            <FormattedMessage id="Lesson locked" />
          </Title>
          <Spacer size="sm" />
          <Subtitle color="charcoal">
            <FormattedMessage id="Complete the previous unit to unlock. dasdad" />
          </Subtitle>
        </Card>
      </LessonOverlay>
      <LessonWrapper>
        <div>
          <Spacer size="lg" />
          <LessonDesc>{desc}</LessonDesc>
        </div>

        <div>
          <Spacer size="lg" />
        </div>
      </LessonWrapper>

      <AITutorLink unit={unit} status="locked" txtColor="black" btnColor="brandPrimary" />
    </Lesson>
  )
}

export default Start
