import { Card, CardContent } from '@components/LPCard/Common'
import * as React from 'react'
import DashCompleted from '../Dashboard/Completed'
import DashJoinOption from '../Dashboard/JoinOption'
import DashLevelUp from '../Dashboard/LevelUpLCS'
import DashLocked from '../Dashboard/Locked'
import DashUnlimited from '../Dashboard/UnlimitedLCS'
import DashUnlocked from '../Dashboard/Unlocked'
import Booked from './Booked'
import Completed from './Completed'
import { IPSpeakingPractice } from './interface'
import Locked from './Locked'
import Unlocked from './Unlocked'

class SpeakingPractice extends React.PureComponent<IPSpeakingPractice> {
  static Locked = Locked
  static Unlocked = Unlocked
  static Booked = Booked
  static Completed = Completed
  static DashLocked = DashLocked
  static DashUnlocked = DashUnlocked
  static DashJoinOption = DashJoinOption
  static DashCompleted = DashCompleted
  static DashUnlimited = DashUnlimited
  static DashLevelUp = DashLevelUp

  render() {
    const { children, withHalo, active } = this.props

    return (
      <Card type="speakPrac" withHalo={withHalo} active={active}>
        <CardContent>{children}</CardContent>
      </Card>
    )
  }
}

export default SpeakingPractice
