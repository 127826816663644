import Spacer from '@berlitz/spacer'
import Button from '@components/Button'
import { Icon, IconCircle, LinkButton, Tag } from '@components/LPCard/Common'
import { Lesson } from '@components/LPCard'
import { CleanHeader, LessonDesc, LessonWrapper, TagContainer } from '@components/LPCard/Lesson/style'
import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { IPStart } from './interface'
import { Lesson0Desc, Lesson0Wrapper, AIWrapper, Subtitle } from './style'
import Compass1 from '@berlitz/streamline-icons/lib/regular/48-Maps-Navigation/01-Compass/Compass1'
import usePortalFeatures from '@components/RoleBasedView/usePortalFeatures'
import AITutorLink from '../Common/AITutorLink'

const Start: React.FC<IPStart> = ({ title, fullname, link, active, unit }) => {
  const isLessonZero = unit?.IsLessonZero
  const cardTitle = (fullname || '').trim()
  const [modalOpen, setModalOpen] = React.useState(false)
  const { state, features } = usePortalFeatures({
    configId: 'c::newLearningExperience',
  })

  return isLessonZero ? (
    <Lesson
      status="start"
      title={cardTitle}
      active={active}
      isLessonZero={isLessonZero}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      language={unit?.StudentProgram?.program?.Language}
    >
      <Lesson0Wrapper>
        <CleanHeader>
          <Icon color="blue100">
            <IconCircle color="white" />
            <Compass1 />
          </Icon>
        </CleanHeader>
        <Spacer size="xxs" />
        <TagContainer>
          <Tag>{title}</Tag>
          <Tag>
            <FormattedMessage id="Not started" />
          </Tag>
        </TagContainer>
        <Spacer size="xs" />
        <div>
          <Lesson0Desc>
            <FormattedMessage
              id="Start here: {cardTitle}"
              defaultMessage="Start here: {cardTitle}"
              values={{ cardTitle }}
            />
          </Lesson0Desc>
        </div>
        <Spacer size="xs" />
        <LinkButton onClick={() => setModalOpen(true)}>
          <FormattedMessage id="What is {cardTitle}?" defaultMessage="What is {cardTitle}?" values={{ cardTitle }} />
        </LinkButton>
        <Spacer size="md" />
        {link && !unit?.HasContractEnded && (
          <div>
            <Button
              color="success"
              narrow
              onClick={() => {
                if (state.allowed) {
                  return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', link)
                }

                window.open(link, '_self')
              }}
            >
              <FormattedMessage id="Start" />
            </Button>
            <Spacer size="lg" />
          </div>
        )}
      </Lesson0Wrapper>

      <AITutorLink unit={unit} status="locked" txtColor="black" btnColor="brandPrimary" />
    </Lesson>
  ) : (
    <Lesson
      status="start"
      title={title}
      active={active}
      isLessonZero={isLessonZero}
      isFromElective={unit?.IsFromElective}
    >
      <LessonWrapper>
        <TagContainer className="status-tag">
          <Tag>{title}</Tag>
          <Tag>
            <FormattedMessage id="Not started" />
          </Tag>
        </TagContainer>
        <div>
          <LessonDesc>{cardTitle}</LessonDesc>
        </div>
        <Spacer size="xl" />
        {link && !unit?.HasContractEnded && (
          <div>
            <Button
              narrow
              onClick={() => {
                if (state.allowed) {
                  return features.cr8.redirectToCR8(unit?.LearningPathId ?? '', link)
                }

                window.open(link, '_self')
              }}
            >
              <FormattedMessage id="Start" />
            </Button>
            <Spacer size="lg" />
          </div>
        )}
      </LessonWrapper>

      <AITutorLink unit={unit} status="locked" txtColor="black" btnColor="brandPrimary" />
    </Lesson>
  )
}

export default Start
