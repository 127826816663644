import * as React from 'react'
import { FormattedMessage } from 'react-intl'
import { CardModal } from '@components/LPCard/Common'

import { IPModal } from './interface'

const WhatModal: React.FC<IPModal> = ({ open, onClose, language = 'English', cardTitle = 'Lesson 0' }) => {
  return (
    <CardModal open={open} onClose={onClose}>
      <CardModal.Title>
        <FormattedMessage id="What is {cardTitle}?" defaultMessage="What is {cardTitle}?" values={{ cardTitle }} />
      </CardModal.Title>
      <CardModal.Desc>
        <FormattedMessage
          id="A chance for you to try out some activities and understand the instructions in {language}."
          defaultMessage="A chance for you to try out some activities and understand the instructions in {language}."
          values={{ language: language }}
        />
      </CardModal.Desc>
      <CardModal.CloseBtn onClick={() => onClose()}>
        <FormattedMessage id="Tap to close" />
      </CardModal.CloseBtn>
    </CardModal>
  )
}

export default WhatModal
