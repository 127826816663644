import { LP_STATUS } from '@utils/constants/learningPath'
import styled, { css } from 'styled-components'

export const ActivityContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  padding-bottom: ${({ theme }) => theme.space.xs};
  width: max-content;

  .line {
    border: 1px solid ${({ theme }) => theme.palette.gray100};
    width: calc(100% - 16px);
    position: absolute;
  }

  .icon-circle {
    width: ${({ theme }) => theme.space.md};
    height: ${({ theme }) => theme.space.md};
    border-radius: ${({ theme }) => theme.space.xxxl};
    border: 1px solid ${({ theme }) => theme.palette.gray100};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.palette.white};
    margin-right: ${({ theme }) => theme.space.sm};
    z-index: 1;

    &.cursor {
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0px;
    }

    &.${LP_STATUS.NOT_YET_STARTED}-circle {
      border: 1px solid ${({ theme }) => theme.palette.gray100};
      background-color: ${({ theme }) => theme.palette.white};
    }

    &.${LP_STATUS.IN_PROGRESS}-circle {
      border: 0px;
      background-color: ${({ theme }) => theme.palette.purple100};
    }

    &.${LP_STATUS.COMPLETE}-circle {
      border: 0px;
      background-color: ${({ theme }) => theme.palette.green100};
    }

    &.selected,
    &.${LP_STATUS.IN_PROGRESS}-circle {
      width: 20px;
      height: 20px;
    }

    .tick {
      svg {
        font-size: ${({ theme }) => theme.fontSize.xxxs};
        color: ${({ theme }) => theme.palette.charcoal};
      }
    }

    ${({ disabled = false }) =>
      disabled &&
      css`
        &.icon-circle {
          cursor: not-allowed;
        }
      `}
  }
`

export const Container = styled.div`
  border-radius: 3px;
  background-color: ${({ theme }) => theme.palette.white};
  padding: ${({ theme }) => theme.space.xs};
  padding-bottom: 0px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.33);
`
